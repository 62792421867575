<template>
  <BaseModal ref="modal" :title="title" max-width="tw-max-w-4xl">
    <div>
      <p><strong>Ingepland voor: </strong> {{ event.start | datetime }} - {{ event.end | time }}</p>
      <p v-if="event.sales_activity">
        <a @click="openLead" class="btn btn-default btn-sm">Bekijk sales lead</a>
        <a @click="confirm" class="btn btn-default btn-sm">Stuur bevestiging</a>
      </p>
      <pre v-if="event.description">{{ event.description }}</pre>
      <p v-else><em>Geen omschrijving ingegeven</em></p>
    </div>
  </BaseModal>
</template>

<script>
import EventBus from '@/components/iam/bus'

export default {
  name: 'EventModal',
  data () {
    return {
      event: {}
    }
  },
  methods: {
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    openLead () {
      this.$router.push({ name: 'LeadDetails', params: { id: this.event.sales_lead } })
      this.$refs.modal.hide()
    },
    confirm () {
      EventBus.$emit('sales-confirm-activity', this.event.sales_lead, this.event.sales_activity)
      this.$refs.modal.hide()
    }
  },
  computed: {
    title () {
      return this.event.title
    }
  }
}
</script>
