<template>
  <HmodhEditCreate ref="hmodhModal" :contact="contact" />
</template>

<script>
import HmodhEditCreate from '@/components/contacts/hmodh/HmodhEditCreate'
import { triggerHmodhCreation } from '@/services/sales'
import { BLANK_HMODH } from '@/utils/helpers'
import { warningModal } from '@/modalMessages'

export default {
  name: 'LeadBreakHmodhFormOpener',
  components: {
    HmodhEditCreate
  },
  data () {
    return {
      contact: null
    }
  },
  methods: {
    async trigger (leadId) {
      const response = await triggerHmodhCreation(leadId)
      if (response?.data?.success === false) {
        if (response?.data?.code) {
          const CODE_MAP = {
            ACTIVE_HMODH: 'De kandidaat heeft al een actieve HMODH. Er kan geen HMODH  worden aangemakt.',
            NO_EMAIL: 'De kandidaat heeft geen e-mailadres. Er kan geen HMODH worden aangemaakt.',
            GLOBAL_UNSUBSCRIBE: 'De kandidaat heeft een rode GDPR-status. Er kan geen HMODH worden aangemaakt.'
          }
          return warningModal(CODE_MAP[response.data.code])
        }
        return // Return without message, as if nothing happened
      }
      const hmodhData = BLANK_HMODH
      hmodhData.niches = response?.data?.niches
      hmodhData.zipcodes = [response?.data?.city]
      hmodhData.types = response?.data?.types
      this.contact = response?.data?.contact
      this.$refs.hmodhModal.hmodh = { ...hmodhData }
      this.$refs.hmodhModal.createHmodhFromBreakLead = true
      this.$refs.hmodhModal.breakLeadId = leadId
      this.$refs.hmodhModal.logId = response?.data?.log_id
      this.$refs.hmodhModal.show()
    }
  }
}
</script>
