<template>
  <div v-if="collaborator">
    <div ref="planner"></div>
    <EventModal ref="modal"></EventModal>
  </div>
</template>

<script>
import $ from 'jquery'
import 'fullcalendar'
import 'fullcalendar-scheduler'
import '../../../node_modules/fullcalendar/dist/locale/nl-be'
import EventModal from './EventModal'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  name: 'iam-planner',
  components: { EventModal },
  props: {
    // Defer rendering of the planner when using it in a modal situation, then call planner.render manually
    deferRender: {
      default: false
    }
  },
  data () {
    return {
      collaborators: [],
      newEvent: {},
      initialized: false
    }
  },
  computed: {
    ...mapState('account', ['user']),
    collaborator () {
      return this.user.collaborator
    }
  },
  mounted () {
    if (!this.deferRender) this._setupPlanner()
    this.$emit('ready')
  },
  methods: {
    /**
       * Component public API's
       */
    addCollaborator (collaborator) {
      if (this.collaborators.indexOf(collaborator) === -1 && this.collaborator.id !== collaborator.id) {
        this.collaborators.push(collaborator)
        this._addResource(collaborator)
        this._addEventSource(collaborator)
      }
    },
    removeCollaborator (collaborator) {
      const idx = this.collaborators.indexOf(collaborator)
      if (idx > -1) {
        this.collaborators.splice(idx, 1)
        this._removeResource(collaborator)
        this._removeEventSource(collaborator)
      }
    },
    render () {
      this._setupPlanner()
      $(this.$refs.planner).fullCalendar('render')
    },
    refresh () {
      if (this.initialized) {
        $(this.$refs.planner).fullCalendar('refetchEvents')
        $(this.$refs.planner).fullCalendar('removeEvents', 'NEW_EVENT')
      }
    },
    setEvent (startDate, endDate, resource) {
      if (this.initialized) {
        $(this.$refs.planner).fullCalendar(
          'select',
          moment(startDate, 'DD-MM-YYYY HH:mm'),
          moment(endDate, 'DD-MM-YYYY HH:mm')
        )
      }
    },

    /**
       * Component internal API's
       */
    _setupPlanner () {
      if (!this.collaborator || !this.collaborator.id) return
      const v = this

      $(this.$refs.planner).fullCalendar({
        schedulerLicenseKey: '0794218191-fcs-1527502778',
        header: {
          left: 'prev,next today',
          center: 'title',
          right: 'month,agendaWeek,agendaFourDay,agendaTwoDay,agendaDay'
        },
        locale: 'nl-be',
        height: 600,
        defaultView: 'agendaWeek',
        groupByResource: true,
        resources: [
          {
            id: this.collaborator.id,
            title: this.collaborator.display_name
          }
        ],
        views: {
          agendaFourDay: {
            type: 'agenda',
            duration: { days: 4 }
          },
          agendaTwoDay: {
            type: 'agenda',
            duration: { days: 2 }
          },
          day: {
            titleFormat: 'dddd DD MMMM YYYY'
          }
        },
        editable: true,
        droppable: true,
        eventStartEditable: true,
        selectable: true,
        selectHelper: true,

        // Called when selecting a timeslot in the planner
        select: (start, end, jsEvent, view, resource) => {
          // remove existing event
          $(v.$refs.planner).fullCalendar('removeEvents', 'NEW_EVENT')

          // Create new event
          const eventData = {
            id: 'NEW_EVENT',
            title: 'Nieuwe activiteit',
            start: start,
            end: end
          }

          if (resource) {
            eventData.resource = resource.id
          }

          // Emit that new event is created
          v.$emit('dateUpdated', {
            startDate: eventData.start.format('DD-MM-YYYY HH:mm'),
            endDate: eventData.end.format('DD-MM-YYYY HH:mm'),
            source: 'planner'
          })

          $(v.$refs.planner).fullCalendar('renderEvent', eventData, true)
          v.newEvent = { start: start, end: end }
        },

        // Called when resizing an existing event
        eventResize: (event, jsEvent, ui, view) => {
          if (event.id === 'NEW_EVENT') {
            v.newEvent = {
              start: event.start,
              end: event.end
            }
            v.$emit('dateUpdated', {
              startDate: event.start.format('DD-MM-YYYY HH:mm'),
              endDate: event.end.format('DD-MM-YYYY HH:mm'),
              source: 'planner'
            })
            return
          }

          const data = {}
          data.start = event.start.format()
          data.end = event.end.format()
          // Emit that the dates have changed
          v.$emit('dateUpdated', {
            startDate: data.start.format('DD-MM-YYYY HH:mm'),
            endDate: data.end.format('DD-MM-YYYY HH:mm'),
            source: 'planner'
          })

          // Post event to Planner API
          $.ajax({
            type: 'PATCH',
            url: `/api/planner/my-feed/${event.id}`,
            data: data
          }).fail(e => console.error(e))
        },

        // Called when moving an existing event
        eventDrop: (event, jsEvent, ui, view) => {
          if (event.id === 'NEW_EVENT') {
            v.newEvent = { start: event.start, end: event.end }
            v.$emit('dateUpdated', {
              startDate: event.start.format('DD-MM-YYYY HH:mm'),
              endDate: event.end.format('DD-MM-YYYY HH:mm'),
              source: 'planner'
            })
            return
          }

          let data = {}
          data.start_date = event.start.format()
          data.end_date = event.end.format()

          $.ajax({
            type: 'PATCH',
            url: `/api/sales/activities/${event.sales_activity}`,
            data: data
          }).fail(e => console.error(e))

          data = {}
          data.start = event.start.format()
          if (!event.end) event.end = event.start.add(1, 'hour')
          data.end = event.end.format()

          v.$emit('dateUpdated', {
            startDate: data.start.format('DD-MM-YYYY HH:mm'),
            endDate: data.end.format('DD-MM-YYYY HH:mm'),
            source: 'planner'
          })

          // Post event to Planner API
          $.ajax({
            type: 'PATCH',
            url: `/api/planner/my-feed/${event.id}`,
            data: data
          }).fail(e => console.error(e))
        },

        // Called when clicking on an existing event
        eventClick: (calEvent, jsEvent, view) => {
          $.ajax({
            type: 'GET',
            url: `/api/planner/my-feed/${calEvent.id}`,
            success: (event) => {
              v.$refs.modal.event = event
              v.$refs.modal.show()
            }
          }).fail(e => console.error(e))
        },

        eventRender: function (event, element) {
          const today = new Date().getTime()
          const eventEnd = moment(event.end).valueOf()
          const eventStart = moment(event.start).valueOf()
          const classes = '!tw-bg-gray-cc !tw-border-gray-cc !tw-opacity-70 !tw-text-tg-color'
          if (!event.end) {
            if (eventStart < today) element.addClass(classes)
          } else {
            if (eventEnd < today) element.addClass(classes)
          }
        }
      })
      /**
         * Add main event source to calendar component
         */
      const eventSource = $(this.$refs.planner).fullCalendar('getEventSourceById', this.collaborator.id)
      if (eventSource === undefined || !eventSource.id) {
        $(this.$refs.planner).fullCalendar('addEventSource', {
          id: this.collaborator.id,
          url: `/api/planner/${this.collaborator.id}`
        })
      }

      this.initialized = true
    },
    _addResource (resource) {
      const planner = this.$refs.planner
      if ($(planner).fullCalendar('getResourceById', resource.id)) {
        return
      }
      $(planner).fullCalendar('addResource', {
        id: resource.id,
        title: resource.display_name
      })
    },
    _removeResource (resource) {
      const planner = this.$refs.planner
      if ($(planner).fullCalendar('getResourceById', resource.id)) {
        $(planner).fullCalendar('removeResource', resource.id)
      }
    },
    _addEventSource (eventSource) {
      const planner = this.$refs.planner
      $(planner).fullCalendar('addEventSource', {
        id: eventSource.id,
        url: `/api/planner/${eventSource.id}`
      })
    },
    _removeEventSource (eventSource) {
      const planner = this.$refs.planner
      $(planner).fullCalendar('removeEventSource', eventSource.id)
    }
  }
}
</script>

<style src="../../../node_modules/fullcalendar/dist/fullcalendar.css"></style>
