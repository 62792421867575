<template>
  <div class='tw-inline tw-mr-4'>
    <input
      type="checkbox"
      class="chk-selected-planner tw-mr-2"
      checked=""
      @click="deselect"
    >
    {{ collaborator.display_name }}
  </div>
</template>

<script>
export default {
  name: 'VisibleCollaboratorItem',
  props: ['collaborator'],
  methods: {
    deselect () {
      this.$emit('deselect', this.collaborator)
    }
  }
}
</script>
