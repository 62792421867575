<template>
  <fieldset
    v-if="isActivityWithEmailSmsConfirmation"
    :class="[
      'tw-mt-8 tw-mb-8 tw-px-2 tw-border-gray-cc tw-rounded',
      { 'tw-mx-[-15px]': addNegativeMargin }
    ]"
  >
    <legend
      class="tw--mx-1 tw-my-0 tw-px-1 tw-w-auto tw-font-bold tw-text-sm tw-text-tg-color"
    >
      Automatische e-mailbevestiging en sms-herinnering
    </legend>
    <div>
      <span
        class="label label-default tw-p-0.5 tw-rounded tw-font-bold tw-mb-2 tw-inline-block"
      >
        {{ firstActivityLabel }}
      </span>
      <div class="tw-flex tw-align-center">
        <input
          type="checkbox"
          id="email"
          name="sendEmailConfirmation"
          v-model="values.sendEmailConfirmation"
          class="!tw-mr-1 !tw-my-0 tw-self-center"
        >
        <label
          for="email"
          class="!tw-mb-0"
        >
          E-mailbevestiging versturen
        </label>
      </div>
      <div class="tw-flex tw-align-center">
        <input
          type="checkbox"
          id="sms"
          name="sendEmailSmsReminder"
          v-model="values.sendEmailSmsReminder"
          class="!tw-mr-1 !tw-my-0 tw-self-center"
          :disabled="emailSmsReminderDisabled"
        >
        <label
          for="email"
          class="!tw-mb-0"
        >
          Sms- en e-mailherinnering (een dag op voorhand) versturen
        </label>
      </div>
      <div
        v-if="values.sendEmailConfirmation || values.sendEmailSmsReminder"
        class="tw-flex tw-flex-wrap tw-align-center tw-gap-x-2 tw-mt-2"
      >
        <div class="tw-flex">
          <input
            type="radio"
            id="location"
            v-model="values.confirmationLocation"
            value="on_location"
            class="!tw-mr-1 !tw-my-0 tw-self-center"
          />
          <label
            for="location"
            class="!tw-my-0 tw-self-center"
          >
            Afspraak ter plaatse
          </label>
        </div>
        <div class="tw-flex">
          <input
            type="radio"
            id="office"
            v-model="values.confirmationLocation"
            value="office"
            class="!tw-mr-1 !tw-my-0 tw-self-center"
          />
          <label
            for="office"
            class="!tw-my-0 tw-self-center"
          >
            Afspraak op kantoor
          </label>
        </div>
        <div class="tw-flex">
          <input
            type="radio"
            id="other"
            v-model="values.confirmationLocation"
            value="other"
            class="!tw-mr-1 !tw-my-0 tw-self-center"
          />
          <label
            for="other"
            class="!tw-my-0 tw-self-center"
          >
            Afspraak op een andere locatie
          </label>
        </div>
        <div v-if="isIntakeActivity && !isFirstActivity" class="tw-flex">
          <input
            type="radio"
            id="phone"
            v-model="values.confirmationLocation"
            value="phone"
            class="!tw-mr-1 !tw-my-0 tw-self-center"
            @change="disableSmsReminder"
          />
          <label
            for="phone"
            class="!tw-my-0 tw-self-center"
          >
            Telefonisch gesprek
          </label>
        </div>
      </div>
      <input
        v-if="values.confirmationLocation === 'other'"
        type="text"
        v-model="values.confirmationAddress"
        placeholder='Adresgegevens'
        class="form-control tw-mt-2"
      >
    </div>
    <div class="tw-mt-2">
      <div
        v-if="values.sendEmailConfirmation && candidatesWithoutEmailAddress"
      >
        <ul>
          <li
            v-for="candidate in candidatesWithoutEmailAddress"
            :key="candidate.id"
          >
            <i class="fa fa-warning" />
            <router-link :to="{ name: 'ContactDetails', params: { id: candidate.id } }">
              {{ candidate.display_name }}
            </router-link> heeft geen e-mailadres
          </li>
        </ul>
      </div>
      <div
        v-if="values.sendEmailSmsReminder && candidatesWithoutMobile"
      >
        <ul>
          <li
            v-for="candidate in candidatesWithoutMobile"
            :key="candidate.id"
          >
            <i class="fa fa-warning" />
            <router-link :to="{ name: 'ContactDetails', params: { id: candidate.id } }">
              {{ candidate.display_name }}
            </router-link> heeft geen gsm-nummer
          </li>
        </ul>
      </div>
    </div>
  </fieldset>
</template>

<script>
import { isFirstActivityOfType } from '@/services/sales'
export default {
  name: 'SMSEmailConfirmation',
  props: {
    activityTypeId: {
      type: Number
    },
    addNegativeMargin: {
      type: Boolean,
      default: false
    },
    lead: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      values: {
        confirmationApplies: false,
        sendEmailConfirmation: true,
        sendEmailSmsReminder: true,
        confirmationLocation: 'on_location',
        confirmationAddress: ''
      },
      isFirstActivity: true
    }
  },
  computed: {
    isVisitActivity () {
      return this.activityTypeId === 2
    },
    isIntakeActivity () {
      return this.activityTypeId === 14
    },
    isActivityWithEmailSmsConfirmation () {
      return this.isVisitActivity || this.isIntakeActivity
    },
    firstActivityLabel () {
      if (this.isVisitActivity && this.isFirstActivity) return 'Eerste bezoek'
      if (this.isVisitActivity && !this.isFirstActivity) return 'Opvolgbezoek'
      if (this.isIntakeActivity && this.isFirstActivity) return 'Eerste gesprek'
      if (this.isIntakeActivity && !this.isFirstActivity) return 'Opvolggesprek'
      return ''
    },
    candidatesWithoutEmailAddress () {
      return this.lead?.candidates.filter(candidate => !candidate.email)
    },
    candidatesWithoutMobile () {
      return this.lead?.candidates.filter(candidate => !candidate.mobile)
    },
    emailSmsReminderDisabled () {
      return this.values.confirmationLocation === 'phone'
    }
  },
  watch: {
    values: {
      deep: true,
      handler (newValues) {
        this.sendDataToParent(newValues)
      }
    },
    activityTypeId () {
      this.$set(this.values, 'confirmationApplies', this.isActivityWithEmailSmsConfirmation)
      if (this.values.confirmationApplies) this.getIsFirstActivityOfType()
    }
  },
  methods: {
    sendDataToParent (newValues) {
      this.$emit('data-updated', newValues)
    },
    async getIsFirstActivityOfType () {
      if (!this.lead?.id) return
      const response = await isFirstActivityOfType(this.lead.id, { activity_type_id: this.activityTypeId })
      this.isFirstActivity = response.data?.is_first_activity
      return response
    },
    disableSmsEmailReminder () {
      if (this.values.confirmationLocation !== 'phone') return
      this.$set(this.values, 'sendEmailSmsReminder', false)
    }
  }
}
</script>
