<template>
  <form class="form-control" style="margin-bottom: 30px; border: 0;" onsubmit="return false;">
    <div class="input-group">
      <input type="text" class="form-control" id="id_collaborator_search"
             placeholder="Zoeken naar medewerkers" v-model.trim="searchString" v-on:keyup.enter="query">
      <span class="input-group-btn">
    <button type="button" class="btn btn-primary" @click="query">
      <i class="glyphicon glyphicon-search"></i>
    </button>
    </span>
    </div>
  </form>
</template>

<script>
export default {
  name: 'CollaboratorSearch',
  data () {
    return {
      searchString: ''
    }
  },
  methods: {
    query () {
      this.$emit('query', this.searchString)
    }
  }
}
</script>

<style scoped>

</style>
