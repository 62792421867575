<template>
  <div id="activity-button-group" class="button-group">
    <button
      type="button"
      v-for="activity in primaryActivities"
      :key="activity.id"
      :class="['btn btn-w-m tw-ml-1 tw-mt-1',  selectedActivity === activity.id ? 'btn-success' : 'btn-default']"
      :data-value="activity.id"
      v-html="activity.name"
      @click="selectPrimaryActivity(activity.id)"
    />
    <div class="btn-group tw-ml-1">

      <button
        type="button"
        id="btn-activity-menu"
        data-toggle="dropdown"
        :class="['btn btn-w-m dropdown-toggle tw-mt-1', secondarySelected ? 'btn-success' : 'btn-default']"
        aria-expanded="false"
      >
        Andere
        <span class="caret" />
      </button>

      <ul id="activity-menu-list" class="dropdown-menu">

        <li class="tw-bg-white">
          <button
            type="button"
            v-for="activity in secondaryActivities"
            :key="activity.key"
            :class="['btn btn-w-m tw-text-xs tw-text-left tw-w-full', { 'btn-primary' : selectedActivity === activity.id }]"
            data-value="activity.id"
            v-html="activity.name"
            @click="selectSecondaryActivity(activity.id)"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import { getSalesActivityTypes } from '@/services/sales'

export default {
  name: 'ActivityButtonGroup',
  data () {
    return {
      selectedActivity: 126, // Outgoing phone call
      secondarySelected: false,
      primaryActivities: [],
      secondaryActivities: []
    }
  },
  computed: {
    primaryActivityIds () {
      return this.primaryActivities.map((a) => a.id)
    }
  },
  created () {
    this.init()
  },
  methods: {
    selectActivity (activityId) {
      this.selectedActivity = activityId
      const activityInPrimary = this.primaryActivityIds.filter(id => id === activityId)
      this.secondarySelected = activityInPrimary.length === 0
      this.$emit('selected-activity-type-id', activityId)
      return activityId
    },
    selectPrimaryActivity (activityId) {
      this.selectActivity(activityId)
    },
    selectSecondaryActivity (activityId) {
      this.selectActivity(activityId)
    },
    getSelectedActivity () {
      return [...this.primaryActivities, ...this.secondaryActivities].find(activity => activity.id === this.selectedActivity)
    },
    async init () {
      const response = await getSalesActivityTypes()
      const activityTypes = response?.data?.results || []
      const primaryActivities = activityTypes.filter(activityType => activityType.is_main).sort((a, b) => a.sort_order - b.sort_order)
      const secondaryActivities = activityTypes.filter(activityType => !activityType.is_main).sort((a, b) => a.name > b.name ? 1 : -1)
      this.primaryActivities = primaryActivities.map(item => {
        return {
          id: item.id,
          name: item.name
        }
      })
      this.secondaryActivities = secondaryActivities.map(item => {
        return {
          id: item.id,
          name: item.name
        }
      })
      this.$emit('initiated')
    }
  }
}
</script>
