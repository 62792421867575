import { httpClient } from './axiosClients'
import { commaSerializer } from './paramSerializers'

export function getHmodhs (payload = {}) {
  const { url = '/api/hmodh', params } = payload
  return httpClient.get(url, {
    params,
    paramsSerializer: commaSerializer
  })
}

export function getHmodhIds ({ params }) {
  return httpClient.get('/api/hmodh/ids', {
    params,
    paramsSerializer: commaSerializer
  })
}

export function getHmodhById (hmodhId) {
  return httpClient.get(`/api/hmodh/${hmodhId}`)
}

export function removeHmodh (hmodhId) {
  return httpClient.delete(`/api/hmodh/${hmodhId}`)
}

export function getHmodhProperties (payload = {}) {
  const { url = '/api/hmodh-properties', params } = payload
  return httpClient.get(url, { params })
}

export function getHmodhPropertiesIds (payload = {}) {
  const { url = '/api/hmodh-properties/ids', params } = payload
  return httpClient.get(url, { params })
}

export function deleteHmodhProperty (hmodhPropertyId) {
  return httpClient.delete(`/api/hmodh-properties/${hmodhPropertyId}`)
}

export function getHmodhMailings (payload) {
  const { hmodhId, url = `/api/hmodh/${hmodhId}/mailings` } = payload
  return httpClient.get(url)
}

export function createHmodh (payload) {
  return httpClient.post('/api/hmodh', payload)
}

export function updateHmodh (hmodhId, payload) {
  return httpClient.patch(`/api/hmodh/${hmodhId}`, payload)
}
