<template>
  <div class="col-sm-3">
    <div class="contact-box center-version">
      <a v-bind:data-collaborator="collaborator.id" @click="select(collaborator)">
        <img alt="image" class="img-circle" src="@/assets/img/profile.png" v-if="!collaborator.picture">
        <img alt="image" class="img-circle" v-bind:src="collaborator.picture" v-if="collaborator.picture">
        <h3 class="m-b-xs"><strong>{{ collaborator.first_name }} {{ collaborator.last_name }}</strong></h3>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollaboratorDisplayBox',
  props: ['collaborator'],
  methods: {
    select (collaborator) {
      this.$emit('select', collaborator)
    }
  }
}
</script>

<style scoped>

</style>
