<template>
  <Modal ref="modal" title="Selecteer medewerker">
    <CollaboratorSearch @query="searchForCollaborators"></CollaboratorSearch>
    <p>{{ prompt }}</p>
    <transition enter-active-class="animated fadeInDown">
      <img src="@/assets/img/loader.gif" v-if="querying">
    </transition>
    <div class="row">
      <CollaboratorDisplayBox v-for="collaborator in assignableCollaborators"
                              :collaborator="collaborator"
                              :key="collaborator.id"
                              @select="selectCollaborator"
      ></CollaboratorDisplayBox>
    </div>
  </Modal>
</template>

<script>
import $ from 'jquery'
import Modal from '@/components/iam/Modal'
import CollaboratorSearch from '@/components/organization/CollaboratorSearch'
import CollaboratorDisplayBox from '@/components/organization/CollaboratorDisplayBox'

export default {
  name: 'SelectCollaboratorModal',
  props: {
    prompt: {
      type: String,
      required: false,
      default: 'Selecteer een collega'
    }
  },
  data () {
    return {
      querying: false,
      assignableCollaborators: {}
    }
  },
  components: {
    Modal: Modal,
    CollaboratorSearch: CollaboratorSearch,
    CollaboratorDisplayBox: CollaboratorDisplayBox
  },
  methods: {
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    selectCollaborator (collaborator) {
      this.$refs.modal.hide()
      this.$emit('select', collaborator)
    },
    searchForCollaborators (query) {
      const v = this
      v.assignableCollaborators = []
      v.querying = true

      $.ajax({
        type: 'GET',
        url: `/api/collaborator?query=${query}`,
        success: (data) => {
          v.assignableCollaborators = data.results
          v.querying = false
        }
      }).fail((e) => console.error(e))
    }
  }
}
</script>

<style scoped>

</style>
